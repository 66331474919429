<script>
	import { onMount } from 'svelte';
	
	export let clipboardTxt;

	let textarea;
	
	onMount(() => {
		textarea.select();
		document.execCommand('copy');
	});
</script>

<textarea bind:value={clipboardTxt} bind:this={textarea}></textarea>

