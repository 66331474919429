<script>
	import { onMount, getContext } from 'svelte';
	import { key } from './rightMenu.js';
	
	export let isDisabled = false;
	export let text = '';
	export let menutype = '';
	
	import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher();	
	
	const { dispatchClick } = getContext(key);
	
	const handleClick = e => {
		if (isDisabled) return;
		dispatch('click');
		dispatchClick(text);
	}
</script>
<style>
	div {
		padding: 4px 15px;
		cursor: default;
		font-size: 14px;
		display: flex;
		align-items: center;
		grid-gap: 5px;
	}
	div.sub {
		padding-left: 30px;
	}
	div.parent:hover, div.sub:hover {
		background: #0002;
	}
	div.disabled {
		color: #0006;
	}
	div.disabled:hover {
		background: white;
	}
</style>
<div class="{menutype}"
  class:disabled={isDisabled}
  on:click={handleClick}
>
	{#if text}
		{text}
	{:else}
		<slot />
	{/if}
</div>
